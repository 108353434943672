import {
  CustomCell,
  CustomRenderer,
  GridCellKind,
  measureTextCached,
  Rectangle,
  roundedRect,
} from '@glideapps/glide-data-grid';

import { colors } from 'quotient/theme/foundations/colors/colors';

import { badgeMapType } from '../types/grid-types';

type BadgeCellProps = {
  readonly kind: 'badge-cell';
  readonly value: string | undefined | null;
  readonly badgeMap: badgeMapType;
};

export type BadgeCell = CustomCell<BadgeCellProps>;

const renderer: CustomRenderer<BadgeCell> = {
  kind: GridCellKind.Custom,
  isMatch: (cell): cell is BadgeCell => (cell.data as any).kind === 'badge-cell',
  draw: (args, cell) => {
    const { ctx, rect } = args;
    const { y, height } = rect;
    const badgeMap = cell.data.badgeMap;
    const value = cell.data.value;
    const text = value && badgeMap[value] ? value && badgeMap[value].label : '';
    const metrics = measureTextCached(text, ctx, 'inter');

    if (!text) {
      return true;
    }

    const innerPad = 6;

    // DrawArea
    const drawArea: Rectangle = {
      x: rect.x + innerPad,
      y: rect.y + innerPad,
      width: rect.width - innerPad * 2,
      height: rect.height - innerPad * 2,
    };
    const centerY = y + height / 2;

    // Badge
    const bgColor = value && badgeMap[value] ? badgeMap[value].bgColor : colors.primaryNeutral[50];

    const badgeDim = {
      width: metrics.width + innerPad * 2,
      height: drawArea.height,
      cornderRadius: 3,
    };

    // Draw rounded rectangle
    roundedRect(ctx, drawArea.x, drawArea.y, badgeDim.width, badgeDim.height, badgeDim.cornderRadius);
    ctx.fillStyle = bgColor;
    ctx.fill();

    // Text
    const boldFont = `600 12px inter`;
    ctx.font = boldFont;
    const textColor = value && badgeMap[value] ? badgeMap[value].textColor : colors.positive[600];
    ctx.fillStyle = textColor;
    ctx.fillText(text, drawArea.x + innerPad, centerY);

    return true;
  },
  measure: (ctx, cell, theme) => {
    const { value } = cell.data;
    return (value ? ctx.measureText(value).width : 0) + theme.cellHorizontalPadding * 2;
  },
};

export default renderer;
