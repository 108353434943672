export enum NUMBER_TYPES {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}

export const CURRENCIES = [
  { translationKey: 'currencies.usd', code: 'USD', symbol: '$', suffix: false },
  { translationKey: 'currencies.eur', code: 'EUR', symbol: '€', suffix: false },
  { translationKey: 'currencies.jpy', code: 'JPY', symbol: '¥', suffix: false },
  { translationKey: 'currencies.gbp', code: 'GBP', symbol: '£', suffix: false },
  { translationKey: 'currencies.aud', code: 'AUD', symbol: 'A$', suffix: false },
  { translationKey: 'currencies.cad', code: 'CAD', symbol: 'C$', suffix: false },
  { translationKey: 'currencies.chf', code: 'CHF', symbol: 'CHF ', suffix: false },
  { translationKey: 'currencies.cny', code: 'CNY', symbol: 'CN¥', suffix: false },
  { translationKey: 'currencies.hkd', code: 'HKD', symbol: 'HK$', suffix: false },
  { translationKey: 'currencies.nzd', code: 'NZD', symbol: 'NZ$', suffix: false },
  { translationKey: 'currencies.sek', code: 'SEK', symbol: 'SEK ', suffix: false },
  { translationKey: 'currencies.krw', code: 'KRW', symbol: '₩', suffix: false },
  { translationKey: 'currencies.sgd', code: 'SGD', symbol: 'SGD ', suffix: false },
  { translationKey: 'currencies.nok', code: 'NOK', symbol: 'NOK ', suffix: false },
  { translationKey: 'currencies.mxn', code: 'MXN', symbol: 'MX$', suffix: false },
  { translationKey: 'currencies.inr', code: 'INR', symbol: '₹', suffix: false },
  { translationKey: 'currencies.rub', code: 'RUB', symbol: 'RUB ', suffix: false },
  { translationKey: 'currencies.zar', code: 'ZAR', symbol: 'ZAR ', suffix: false },
  { translationKey: 'currencies.try', code: 'TRY', symbol: 'TRY ', suffix: false },
  { translationKey: 'currencies.brl', code: 'BRL', symbol: 'R$', suffix: false },
  { translationKey: 'currencies.twd', code: 'TWD', symbol: 'NT$', suffix: false },
  { translationKey: 'currencies.dkk', code: 'DKK', symbol: 'DKK ', suffix: false },
  { translationKey: 'currencies.pln', code: 'PLN', symbol: 'PLN ', suffix: false },
  { translationKey: 'currencies.thb', code: 'THB', symbol: 'THB ', suffix: false },
  { translationKey: 'currencies.idr', code: 'IDR', symbol: 'IDR ', suffix: false },
  { translationKey: 'currencies.huf', code: 'HUF', symbol: 'HUF ', suffix: false },
  { translationKey: 'currencies.czk', code: 'CZK', symbol: 'CZK ', suffix: false },
  { translationKey: 'currencies.ils', code: 'ILS', symbol: '₪', suffix: false },
  { translationKey: 'currencies.clp', code: 'CLP', symbol: 'CLP ', suffix: false },
  { translationKey: 'currencies.php', code: 'PHP', symbol: 'PHP ', suffix: false },
  { translationKey: 'currencies.aed', code: 'AED', symbol: 'Dhs ', suffix: false },
  { translationKey: 'currencies.cop', code: 'COP', symbol: 'COP ', suffix: false },
  { translationKey: 'currencies.sar', code: 'SAR', symbol: 'SAR ', suffix: false },
  { translationKey: 'currencies.myr', code: 'MYR', symbol: 'MYR ', suffix: false },
  { translationKey: 'currencies.ron', code: 'RON', symbol: 'RON ', suffix: false },
  { translationKey: 'currencies.lkr', code: 'LKR', symbol: 'LKR ', suffix: false },
  { translationKey: 'currencies.vnd', code: 'VND', symbol: '₫', suffix: true },
  { translationKey: 'currencies.qar', code: 'QAR', symbol: 'QR', suffix: false },
  { translationKey: 'currencies.bgn', code: 'BGN', symbol: 'BGN ', suffix: false },
  { translationKey: 'currencies.bdt', code: 'BDT', symbol: '৳ ', suffix: false },
  { translationKey: 'currencies.crc', code: 'CRC', symbol: '₡ ', suffix: false },
  { translationKey: 'currencies.pen', code: 'PEN', symbol: 'S/ ', suffix: false },
  { translationKey: 'currencies.uah', code: 'UAH', symbol: '₴', suffix: false },
  { translationKey: 'currencies.egp', code: 'EGP', symbol: 'E£', suffix: false },
  { translationKey: 'currencies.jod', code: 'JOD', symbol: 'JOD ', suffix: false },
  { translationKey: 'currencies.kes', code: 'KES', symbol: 'KSh', suffix: false },
  { translationKey: 'currencies.pkr', code: 'PKR', symbol: 'Rs', suffix: false },
  { translationKey: 'currencies.ars', code: 'ARS', symbol: 'ARS ', suffix: false },
  { translationKey: 'currencies.bhd', code: 'BHD', symbol: 'BD ', suffix: false },
  { translationKey: 'currencies.rwf', code: 'RWF', symbol: 'FRw ', suffix: false },
  { translationKey: 'currencies.kwd', code: 'KWD', symbol: 'KD', suffix: false },
  { translationKey: 'currencies.gel', code: 'GEL', symbol: 'ლ', suffix: false },
  { translationKey: 'currencies.pyg', code: 'PYG', symbol: '₲', suffix: false },
  { translationKey: 'currencies.amd', code: 'AMD', symbol: '֏', suffix: false },
  { translationKey: 'currencies.dop', code: 'DOP', symbol: 'RD$', suffix: false },
  { translationKey: 'currencies.hnl', code: 'HNL', symbol: 'L', suffix: false },
  { translationKey: 'currencies.bam', code: 'BAM', symbol: 'KM', suffix: false },
  { translationKey: 'currencies.sdg', code: 'SDG', symbol: 'LS', suffix: false },
  { translationKey: 'currencies.uyu', code: 'UYU', symbol: '$U', suffix: false },
  { translationKey: 'currencies.lbp', code: 'LBP', symbol: 'LL', suffix: false },
  { translationKey: 'currencies.xof', code: 'XOF', symbol: 'F.CFA', suffix: false },
  { translationKey: 'currencies.ngn', code: 'NGN', symbol: '₦', suffix: false },
  { translationKey: 'currencies.omr', code: 'OMR', symbol: '﷼', suffix: false },
  { translationKey: 'currencies.tnd', code: 'TND', symbol: 'د.ت', suffix: false },
];

export const BOOLEAN_OPTIONS = [
  { label: 'TRUE', value: 'TRUE' },
  { label: 'FALSE', value: 'FALSE' },
];
