import { faPen, faPlus, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { faRaindrops } from '@fortawesome/pro-regular-svg-icons/faRaindrops';
import { faSnowflake } from '@fortawesome/pro-regular-svg-icons/faSnowflake';
import { EditableGridCell, GridCell, GridCellKind, GridColumn, Item, Rectangle } from '@glideapps/glide-data-grid';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayer } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';

import { Icon } from 'quotient';

import { DropdownCell } from '../components/DropdownCell';
import { useGlideContext } from '../context/GlideContext';
import { getCellValue } from '../data-grid-utils';
import { NumberFormatter } from '../formatters/NumberFormatter';
import {
  badgeMapType,
  CellContentConfigType,
  CellContextMenuItemConfig,
  DataGridColumn,
  DataGridDropdownConfig,
  DataRecord,
  DeleteRowConfig,
  ERROR_VALUE_TO_DISPLAY_VALUE,
  GridCellUpdate,
  HeaderContextMenuConfig,
  MenuGroupOption,
  RowDataTypes,
} from '../types/grid-types';
import { BOOLEAN_OPTIONS } from '../utils/constants';

export function useGetDataGridEventHandlers(records: DataRecord[]) {
  const { gridColumns, setGridColumns } = useGlideContext();

  const getCellContent = useCallback(
    (
      cell: Item,
      dropdownOptionsMapper?: (
        data: Record<string, RowDataTypes>,
        columnId: string,
      ) => { label: string; value: string }[],
      dropdownConfig?: DataGridDropdownConfig,
      cellContentConfig?: CellContentConfigType,
      dateFormatter?: (dateInput: string | Date | undefined, format: string) => string,
      badgeMap?: badgeMapType,
      // eslint-disable-next-line sonarjs/cognitive-complexity
    ): GridCell => {
      let cellContent: GridCell;

      const [colIndex, rowIndex] = cell;
      const dataRow = records && records[rowIndex] && records[rowIndex].data;
      const columnId = gridColumns[colIndex].id;
      const cellData = dataRow?.[columnId];

      const columnDefinition = gridColumns[colIndex];

      if (columnDefinition.type === 'number') {
        const cellDataFormatted = (cellData ?? '').toString();
        let displayData = '';
        let effectiveData = cellData;
        if (typeof cellData === 'string' && cellData in ERROR_VALUE_TO_DISPLAY_VALUE) {
          displayData = ERROR_VALUE_TO_DISPLAY_VALUE[cellData];
        } else {
          displayData = cellDataFormatted;
          if (columnDefinition.format) {
            const { formattedValueWithPrefixSuffix, formattedValue } = NumberFormatter.formatForDisplay(
              cellDataFormatted,
              columnDefinition.format,
            );
            displayData = formattedValueWithPrefixSuffix;
            effectiveData = formattedValue || effectiveData;
          }
        }

        cellContent = {
          kind: GridCellKind.Number,
          allowOverlay: columnDefinition.editable,
          displayData,
          data: effectiveData as number,
        };
      } else if (columnDefinition.type === 'boolean') {
        const currentOption = BOOLEAN_OPTIONS.find((option) => {
          return option.value === cellData?.toString().toUpperCase();
        });

        cellContent = {
          kind: GridCellKind.Custom,
          allowOverlay: columnDefinition.editable,
          copyData: currentOption?.value as string,
          readonly: false,
          data: {
            kind: 'dropdown-cell',
            allowedValues: BOOLEAN_OPTIONS,
            value: currentOption?.value,
            label: currentOption?.label,
            rowData: dataRow,
            columnDefinition,
          },
        };
      } else if (columnDefinition.type === 'dropdown' && (dropdownOptionsMapper || dropdownConfig?.asyncOptions)) {
        let dropdownOptions;
        let currentOption;
        if (dropdownOptionsMapper) {
          dropdownOptions = dropdownOptionsMapper(dataRow, columnDefinition?.id as string);
          currentOption = dropdownOptions.find((option) => {
            return option.value === cellData;
          });
        } else {
          currentOption = { label: cellData, value: cellData };
        }

        cellContent = {
          kind: GridCellKind.Custom,
          allowOverlay: columnDefinition.editable,
          copyData: currentOption?.value as string,
          readonly: false,
          data: {
            kind: 'dropdown-cell',
            allowedValues: dropdownOptions || [],
            value: currentOption?.value,
            label: currentOption?.label,
            asyncOptions: dropdownConfig?.asyncOptions,
            rowData: dataRow,
            columnDefinition,
          },
        };
      } else if (columnDefinition.type === 'date') {
        const cellDataFormatted = (cellData ?? '').toString();
        let displayData = '';
        try {
          if (cellDataFormatted === '') {
            displayData = '';
          } else if (typeof cellData === 'string' && cellData in ERROR_VALUE_TO_DISPLAY_VALUE) {
            displayData = ERROR_VALUE_TO_DISPLAY_VALUE[cellData];
          } else if (typeof dateFormatter === 'function') {
            displayData = dateFormatter(cellDataFormatted, 'dateShort');
          } else {
            displayData = new Intl.DateTimeFormat().format(new Date(cellDataFormatted));
          }
        } catch (error) {
          console.error('Error formatting date', error);
        }

        cellContent = {
          kind: GridCellKind.Text,
          allowOverlay: columnDefinition.editable,
          displayData,
          data: cellData as string,
        };
      } else if (columnDefinition.type === 'badge' && badgeMap) {
        cellContent = {
          kind: GridCellKind.Custom,
          allowOverlay: false,
          readonly: true,
          data: {
            kind: 'badge-cell',
            value: cellData,
            badgeMap,
          },
          copyData: cellData as string,
        };
      } else {
        // Default to a text cell editor
        cellContent = {
          kind: GridCellKind.Text,
          allowOverlay: columnDefinition?.editable || false,
          displayData: cellData?.toString() ?? '',
          data: cellData?.toString() ?? '',
        };
      }

      // post processing of cell content
      if (typeof cellContentConfig?.postGetCellContent === 'function') {
        cellContent = cellContentConfig.postGetCellContent({
          cell,
          cellData,
          columnDefinition,
          gridCellContent: cellContent,
        });
      }

      return cellContent;
    },
    [gridColumns, records],
  );

  const getNewCellData = useCallback(
    (cell: Item, newCellValue: EditableGridCell) => {
      const [colIndex] = cell;
      const columnDefinition = gridColumns[colIndex];

      // Get the raw value of the cell
      const rawCellValue = getCellValue(newCellValue, columnDefinition);

      let newCellData;
      // For dropdowns we just use the option value and don't do any additional formatting.
      if (newCellValue.kind === 'custom') {
        newCellData = (newCellValue as DropdownCell).data;
      } else {
        newCellData = rawCellValue; // In the non-dropdown case, the data just points to the actual primitive data
      }

      return newCellData as EditableGridCell['data'];
    },
    [gridColumns],
  );

  const getCellData = (dataRecords: DataRecord[], columns: DataGridColumn[], cell: Item) => {
    const [colIndex, rowIndex] = cell;
    const column = columns[colIndex];
    const rowId = dataRecords[rowIndex].id;
    const currentCellValue = dataRecords[rowIndex]?.data[column.id];
    return {
      column,
      rowId,
      value: currentCellValue,
    };
  };

  const onCellEdited = useCallback(
    (
      cell: Item,
      newCellValue: EditableGridCell,
      onCellDataPushed: (
        cell: Item,
        newCellData: EditableGridCell,
        previousCellData: { column: DataGridColumn; rowId: string; value: RowDataTypes | undefined },
      ) => void,
    ) => {
      const previousCellData = getCellData(records, gridColumns, cell);
      onCellDataPushed(cell, newCellValue, previousCellData);
    },
    [records, gridColumns],
  );

  const onCellsEdited = useCallback(
    (
      newValues: readonly { location: Item; value: EditableGridCell }[],
      onCellsDataPushed: (cellUpdates: { cell: Item; newCellValue: EditableGridCell }[]) => void,
    ): boolean | void => {
      const cellUpdates: GridCellUpdate[] = [];

      newValues.forEach((value) => {
        const newCellData = getNewCellData(value.location, value.value);

        const cellValueCopy = { ...value.value };
        const cellValueCopyUpdated = { ...cellValueCopy, data: newCellData } as EditableGridCell;

        cellUpdates.push({
          cell: value.location,
          newCellValue: cellValueCopyUpdated,
        });
      });
      onCellsDataPushed(cellUpdates);
      return true;
    },
    [getNewCellData],
  );

  const onColumnResize = useCallback(
    (column: GridColumn, newSize: number) => {
      setGridColumns((prevColumns: DataGridColumn[]) => {
        const index = prevColumns.findIndex((ci) => ci.id === column.id);
        const newColumnsArray = [...prevColumns];

        // Update the associated column with the new width
        newColumnsArray.splice(index, 1, {
          ...prevColumns[index],
          width: newSize,
        });
        return newColumnsArray;
      });
    },
    [setGridColumns],
  );

  const onRowAppended = useCallback(() => {}, []);

  return { getCellContent, onCellEdited, onCellsEdited, onColumnResize, onRowAppended };
}

const useEditColumn = (
  headerContextMenuConfig: HeaderContextMenuConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      headerContextMenuConfig?.editColumnConfig?.enabled === true
        ? [
            {
              title: t('header_context.edit_column'),
              action: () => {
                if (contextMenuPosition?.col !== undefined && headerContextMenuConfig.editColumnConfig?.onEditColumn) {
                  headerContextMenuConfig.editColumnConfig.onEditColumn(contextMenuPosition.col);
                }
              },
              grouping: 'default' as MenuGroupOption,
              icon: <Icon icon={faPen} />,
            },
          ]
        : [],
    [t, headerContextMenuConfig.editColumnConfig, contextMenuPosition?.col],
  );
};

const useFrozenColumnItems = (
  headerContextMenuConfig: HeaderContextMenuConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) => {
  const { frozenColumn, setFrozenColumn, selection } = useGlideContext();
  const { t } = useTranslation();
  const isBulkSelecting = selection.columns.length > 1;
  return useMemo(
    () =>
      headerContextMenuConfig?.frozenColumnConfig?.enabled === true
        ? [
            {
              title: t('header_context.freeze_column', { count: selection.columns.length }),
              action: () => {
                const newFrozenColumn = isBulkSelecting ? selection.columns.last() : contextMenuPosition?.col;
                if (newFrozenColumn !== undefined) {
                  setFrozenColumn(newFrozenColumn + 1);
                  if (headerContextMenuConfig.frozenColumnConfig?.onFreezeColumn) {
                    headerContextMenuConfig.frozenColumnConfig.onFreezeColumn(newFrozenColumn + 1);
                  }
                }
              },
              grouping: 'freeze' as MenuGroupOption,
              icon: <Icon icon={faSnowflake} />,
            },
            {
              title: t('header_context.unfreeze_columns'),
              action: () => {
                setFrozenColumn(0);
                if (headerContextMenuConfig.frozenColumnConfig?.onFreezeColumn) {
                  headerContextMenuConfig.frozenColumnConfig.onFreezeColumn(0);
                }
              },
              grouping: 'freeze' as MenuGroupOption,
              icon: <Icon icon={faRaindrops} />,
              disabled: frozenColumn === 0,
            },
          ]
        : [],
    [
      t,
      headerContextMenuConfig.frozenColumnConfig,
      isBulkSelecting,
      frozenColumn,
      selection.columns,
      contextMenuPosition?.col,
      setFrozenColumn,
    ],
  );
};

const useDeleteColumns = (
  headerContextMenuConfig: HeaderContextMenuConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) => {
  const { selection } = useGlideContext();
  const { t } = useTranslation();
  const isDeleteEnabled = !!headerContextMenuConfig.deleteColumnConfig?.enabled;
  return useMemo(
    () =>
      isDeleteEnabled
        ? [
            {
              title: t('header_context.delete_column', { count: selection.columns.length }),
              action: () => {
                if (
                  contextMenuPosition?.col !== undefined &&
                  headerContextMenuConfig.deleteColumnConfig?.onDeleteColumn
                ) {
                  const selectedColumnIndexes = selection.columns.toArray();
                  headerContextMenuConfig.deleteColumnConfig.onDeleteColumn(selectedColumnIndexes);
                }
              },
              grouping: 'destructive' as MenuGroupOption,
              icon: <Icon icon={faTrashCan} />,
            },
          ]
        : [],
    [isDeleteEnabled, t, selection.columns, contextMenuPosition?.col, headerContextMenuConfig.deleteColumnConfig],
  );
};

const useAddColumns = (
  headerContextMenuConfig: HeaderContextMenuConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) => {
  const { t } = useTranslation();
  const isAddLeftColumnEnabled = !!headerContextMenuConfig.addLeftColumnConfig?.enabled;
  const isAddRightColumnEnabled = !!headerContextMenuConfig.addRightColumnConfig?.enabled;
  return useMemo(
    () => [
      ...(isAddLeftColumnEnabled
        ? [
            {
              title: t('header_context.add_column_left'),
              action: () => {
                if (
                  contextMenuPosition?.col !== undefined &&
                  headerContextMenuConfig.addLeftColumnConfig?.onAddColumn
                ) {
                  headerContextMenuConfig.addLeftColumnConfig.onAddColumn(contextMenuPosition.col);
                }
              },
              grouping: 'default' as MenuGroupOption,
              icon: <Icon icon={faPlus} />,
            },
          ]
        : []),
      ...(isAddRightColumnEnabled
        ? [
            {
              title: t('header_context.add_column_right'),
              action: () => {
                if (
                  contextMenuPosition?.col !== undefined &&
                  headerContextMenuConfig.addRightColumnConfig?.onAddColumn
                ) {
                  headerContextMenuConfig.addRightColumnConfig.onAddColumn(contextMenuPosition.col);
                }
              },
              grouping: 'default' as MenuGroupOption,
              icon: <Icon icon={faPlus} />,
            },
          ]
        : []),
    ],
    [
      contextMenuPosition?.col,
      headerContextMenuConfig.addLeftColumnConfig,
      headerContextMenuConfig.addRightColumnConfig,
      isAddLeftColumnEnabled,
      isAddRightColumnEnabled,
      t,
    ],
  );
};

export function useGetHeaderContextMenuItems(
  headerContextMenuConfig: HeaderContextMenuConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) {
  const frozenColumnItems = useFrozenColumnItems(headerContextMenuConfig, contextMenuPosition);
  const deleteColumnItems = useDeleteColumns(headerContextMenuConfig, contextMenuPosition);
  const editColumnItems = useEditColumn(headerContextMenuConfig, contextMenuPosition);
  const addColumnItems = useAddColumns(headerContextMenuConfig, contextMenuPosition);
  const headerContextMenuItems = useMemo(
    () => [
      ...deleteColumnItems,
      ...frozenColumnItems,
      ...editColumnItems,
      ...addColumnItems,
      ...(headerContextMenuConfig?.customItems ?? []),
    ],
    [deleteColumnItems, frozenColumnItems, editColumnItems, addColumnItems, headerContextMenuConfig?.customItems],
  );
  return { headerContextMenuItems };
}

const useDeleteRows = (deleteRowConfig?: DeleteRowConfig, contextMenuPosition?: { col: number; bounds: Rectangle }) => {
  const { selection } = useGlideContext();
  const { t } = useTranslation();
  const isDeleteEnabled = !!deleteRowConfig?.enabled;
  return useMemo(
    () =>
      isDeleteEnabled && contextMenuPosition?.col === -1
        ? [
            {
              title: t('cell_context.delete_rows', { count: selection.rows.length }),
              action: () => {
                if (contextMenuPosition?.col !== undefined && deleteRowConfig?.onDeleteRow) {
                  let selectedRowIndexes = selection.rows.toArray();
                  if (
                    contextMenuPosition.col === -1 && // clicked row index column
                    selection.rows.length === 0 && // no rows are selected
                    selection.current?.cell[1] !== undefined
                  ) {
                    // consider the row where the context menu was opened as the 'selected' row
                    selectedRowIndexes = [selection.current?.cell[1]];
                  }
                  deleteRowConfig.onDeleteRow(selectedRowIndexes);
                }
              },
              grouping: 'destructive' as MenuGroupOption,
              icon: <Icon icon={faTrashCan} />,
            },
          ]
        : [],
    [isDeleteEnabled, contextMenuPosition?.col, t, selection, deleteRowConfig],
  );
};

export function useGetCellContextMenuItems(
  cellContextMenuConfig: CellContextMenuItemConfig = {},
  contextMenuPosition?: { col: number; bounds: Rectangle },
) {
  const deleteRowItems = useDeleteRows(cellContextMenuConfig.deleteRowConfig, contextMenuPosition);
  const cellContextMenuItems = useMemo(() => [...deleteRowItems, ...(cellContextMenuConfig?.customItems ?? [])], [
    deleteRowItems,
    cellContextMenuConfig?.customItems,
  ]);
  return { cellContextMenuItems };
}

export function useGetLayerConfiguration(
  isOpen: boolean,
  contextMenuPosition: { col: number; bounds: Rectangle } | undefined,
  contextMenuPlacement: PlacementType,
  setContextMenuPosition: Function,
) {
  const { layerProps: contextMenulayerProps } = useLayer({
    isOpen,
    auto: true,
    placement: contextMenuPlacement,
    triggerOffset: 2,
    onOutsideClick: () => {
      setContextMenuPosition(undefined);
    },
    trigger: {
      getBounds: () => ({
        left: contextMenuPosition?.bounds.x ?? 0,
        top: contextMenuPosition?.bounds.y ?? 0,
        width: contextMenuPosition?.bounds.width ?? 0,
        height: contextMenuPosition?.bounds.height ?? 0,
        right: (contextMenuPosition?.bounds.x ?? 0) + (contextMenuPosition?.bounds.width ?? 0),
        bottom: (contextMenuPosition?.bounds.y ?? 0) + (contextMenuPosition?.bounds.height ?? 0),
      }),
    },
  });

  return contextMenulayerProps;
}
