export type MenuGroupOption = 'default' | 'freeze' | 'additive' | 'destructive';
export const DEFAULT_GROUPING = 'default';

export type MenuItem = {
  title: string;
  action: () => void;
  grouping: MenuGroupOption;
  icon: JSX.Element;
  subMenu?: JSX.Element;
  disabled?: boolean;
  // in the future we may want to consider adding contextual information like the current cell and column
};
