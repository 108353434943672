import { createInstance } from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';

export const i18n = createInstance({
  lng: 'en-US',
  fallbackLng: 'en-US',
  debug: false,
});

i18n.use(resourcesToBackend((language: string) => import(`../public/locales/${language}/translation.json`)));
i18n.init();
