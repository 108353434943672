import { EditableGridCell, Item } from '@glideapps/glide-data-grid';
import { DataRecord } from './grid-types';

export enum GridChangeEventType {
  CELL_EDITED = 'CELL_EDITED',
  RECORD_DELETED = 'RECORD_DELETED',
}

export type GridChangeEventBase = {
  id: string;
  created_at: number; // UTC timestamp
  type: GridChangeEventType;
};

export type GridCellEditEvent = GridChangeEventBase & {
  cell: Item;
  newCellValue: EditableGridCell;
  type: GridChangeEventType.CELL_EDITED;
};

export type GridRecordDeleteEvent = GridChangeEventBase & {
  rowIndex: number;
  row: DataRecord;
  type: GridChangeEventType.RECORD_DELETED;
};

export type GridChangeEvent = GridCellEditEvent | GridRecordDeleteEvent;
