import {
  chakra,
  ComponentMultiStyleConfig,
  forwardRef,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
  omitThemingProps,
  useFormControl,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { Icon } from 'quotient';
import { textStyles } from 'quotient/theme/foundations/typography/typography';
import { SelectedStyledSystemProps, CommonCIQProps } from 'quotient/types';

export const InputStyle: ComponentMultiStyleConfig = {
  parts: ['field'],
  baseStyle: {
    field: {
      textStyle: 'bodyRegular',
      textOverflow: 'ellipsis',
      width: '100%',
      height: '36px',
      paddingY: 1.5,
      _placeholder: { fontStyle: 'italic', color: 'textLowContrast' },
      bg: 'surfaceBackgroundHighContrast',
      border: '1px solid',
      color: 'textHighContrast',
      _active: { borderColor: 'primary.300' },
      _disabled: { cursor: 'not-allowed', bgColor: 'surfaceBackgroundMediumContrast', color: 'textLowContrast' },
      _invalid: { borderColor: 'textDestructive' },
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: textStyles.bodyRegular.fontSize,
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: 'textLowContrast',
        borderRadius: '4px',
        _focus: {
          borderColor: 'primary.300',
          outlineWidth: '2px',
          boxShadow: 'none',
          outlineColor: 'primary.300',
          outlineOffset: 0,
        },
        _focusVisible: {
          borderColor: 'primary.300',
          outlineWidth: '2px',
          boxShadow: 'none',
          outlineColor: 'primary.300',
          outlineOffset: 0,
        },
        _hover: { borderColor: 'textLowContrast' },
      },
    },
  },
};

const QInput = forwardRef<InputProps & CommonCIQProps, 'input'>((props, ref) => {
  const { htmlSize, ...rest } = props;

  const styles = useMultiStyleConfig('Input', rest);
  const ownProps = omitThemingProps(rest);
  const input = useFormControl<HTMLInputElement>(ownProps);
  const _className = cx('chakra-input', props.className);

  return <chakra.input size={htmlSize} {...input} __css={styles.field} className={_className} ref={ref} />;
});

export type QuotientInputProps = {
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  // needed to implement other quotient components only, not for general use
  type?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onRightIconClick?: () => void;
  onLeftIconClick?: () => void;
  defaultValue?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  name?: string;
  max?: string | number;
  min?: string | number;
} & SelectedStyledSystemProps &
  CommonCIQProps;

const PADDING_X = 3;
const ICON_WIDTH = 4;
const ICON_GAP = 2;

export const QuotientInput = forwardRef<QuotientInputProps, 'input'>(
  (
    {
      defaultValue,
      value,
      placeholder,
      isDisabled,
      isInvalid,
      leftIcon,
      rightIcon,
      type = 'text',
      onChange,
      onBlur,
      onLeftIconClick,
      onRightIconClick,
      'data-testid': dataTestId,
      id,
      autoFocus,
      autoComplete,
      name,
      max,
      min,
      ...rest
    }: QuotientInputProps,
    ref,
  ) => {
    const paddingLeft = leftIcon ? PADDING_X + ICON_WIDTH + ICON_GAP : PADDING_X;
    const paddingRight = PADDING_X + ICON_WIDTH + ICON_GAP;
    const iconColor = isDisabled ? 'textLowContrast' : 'textHighContrast';
    return (
      <InputGroup {...rest}>
        {leftIcon && (
          <InputLeftElement>
            <Icon color={iconColor} data-testid="left-icon" icon={leftIcon} size="sm" onClick={onLeftIconClick} />
          </InputLeftElement>
        )}
        <QInput
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          data-testid={dataTestId || 'input-testid'}
          defaultValue={defaultValue}
          disabled={isDisabled}
          id={id}
          isInvalid={isInvalid}
          max={max}
          min={min}
          name={name}
          paddingLeft={paddingLeft}
          paddingRight={paddingRight}
          placeholder={placeholder}
          ref={ref}
          type={type}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
        {rightIcon && (
          <InputRightElement>
            <Icon color={iconColor} data-testid="right-icon" icon={rightIcon} size="sm" onClick={onRightIconClick} />
          </InputRightElement>
        )}
      </InputGroup>
    );
  },
);
