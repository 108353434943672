import { TreeItem, TreeItemIndex } from 'react-complex-tree';

import { NavigatorWorksheet, NavigatorDataObject } from './types';

type FormattedTreeData = Record<TreeItemIndex, TreeItem<NavigatorDataObject>>;

const componentColors: string[] = ['secondary.600', 'tertiary.600', 'quaternary.600', 'primary.600'];

const componentColorMap = new Map();

const getComponentColor = (worksheet: NavigatorWorksheet): string => {
  if (!worksheet.component_instance_id) {
    return 'none';
  }
  if (!componentColorMap.has(worksheet.component_instance_id)) {
    const colorIndex = componentColorMap.size % componentColors.length;
    componentColorMap.set(worksheet.component_instance_id, componentColors[colorIndex]);
    return componentColors[colorIndex];
  }
  if (componentColorMap.has(worksheet.component_instance_id)) {
    return componentColorMap.get(worksheet.component_instance_id);
  }
  return 'none';
};

export const getTreeData = (worksheets: NavigatorWorksheet[]): FormattedTreeData => {
  const formattedTreeData: FormattedTreeData = {
    root: {
      index: 'root',
      isFolder: true,
      children: [],
      data: {
        object: 'root',
        id: 'root',
        name: 'root',
        description: 'root',
        type: 'root',
        is_hidden: false,
      },
    },
  };

  worksheets.forEach((worksheet: NavigatorWorksheet) => {
    const componentGroupColor = getComponentColor(worksheet);
    formattedTreeData[worksheet.id] = {
      index: worksheet.id,
      isFolder: false,
      children: [],
      data: {
        ...worksheet,
        componentGroupColor,
      },
    };
    formattedTreeData.root.children?.push(worksheet.id);
  });

  return formattedTreeData;
};
