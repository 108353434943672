import { ComponentMultiStyleConfig, forwardRef, Select } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

import { Icon } from 'quotient';
import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

export const SelectStyle: ComponentMultiStyleConfig = {
  parts: ['field'],
  baseStyle: {
    field: {
      appearance: 'none',
      height: '36px',
      boxSizing: 'border-box',
      textStyle: 'bodyRegular',
      width: '100%',
      paddingLeft: 3,
      // padding + icon + plus padding
      paddingRight: 3 + 4 + 4,
      paddingY: 1.5,
      bg: 'surfaceBackgroundHighContrast',
      border: '1px solid',
      borderColor: 'textLowContrast',
      borderRadius: '4px',
      _invalid: { borderColor: 'surfaceDestructiveDefault' },
      _focus: { outlineColor: 'primary.300' },
      _focusVisible: { outlineColor: 'primary.300' },
      _active: { borderColor: 'primary.300' },
      _disabled: { cursor: 'not-allowed', bgColor: 'surfaceBackgroundMediumContrast', color: 'textLowContrast' },
    },
    icon: {
      right: 3,
    },
  },
};

export type QuotientSelectProps = {
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isInvalid?: boolean;
  leftIcon?: IconDefinition;
  children: JSX.IntrinsicElements['option'] | JSX.IntrinsicElements['option'][];
  onChange?: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientSelect = forwardRef<QuotientSelectProps, 'select'>(
  ({ value, placeholder, isDisabled, isInvalid, onChange, children, ...rest }: QuotientSelectProps, ref) => {
    return (
      <Select
        color={value === '' && placeholder ? 'textLowContrast' : 'textHighContrast'}
        fontStyle={value === '' && placeholder ? 'italic' : 'normal'}
        icon={<Icon color="textHighContrast" icon={faChevronDown} size="sm" />}
        isDisabled={isDisabled}
        isInvalid={isInvalid}
        placeholder={placeholder}
        ref={ref}
        styleConfig={SelectStyle}
        value={value}
        onChange={onChange}
        {...rest}
      >
        {children}
      </Select>
    );
  },
);
