import { forwardRef, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ReactNode } from 'react';

import { SelectedStyledSystemProps } from 'quotient/types';

import { QuotientIcon } from '../Icon/Icon';

export const TagStyle: ComponentStyleConfig = {
  baseStyle: {
    container: {
      flexShrink: 0,
      // chakra must have something overriding paddingLeft/Right for the container, but this works
      padding: '0 6px 0 4px',
      boxShadow: 'none',
      gap: 1,
    },
    label: {
      textStyle: 'smallRegular',
    },
    closeButton: {
      margin: 0,
      padding: 0,
      height: '10px',
      width: '10px',
      border: 'none',
      _focus: {
        outline: 'none',
        boxShadow: 'none',
        bg: 'transparent',
      },
      bg: 'transparent',
      _hover: {
        bg: 'transparent',
      },
    },
  },
  sizes: {
    sm: {
      container: {
        height: '20px',
      },
    },
    md: {
      container: {
        height: '24px',
      },
    },
  },
  variants: {
    neutral: {
      container: {
        bg: 'primaryNeutral.200',
        color: 'primaryNeutral.900',
      },
      closeButton: {
        color: 'primaryNeutral.600',
        _hover: {
          color: 'primaryNeutral.900',
        },
      },
    },
    brand: {
      container: {
        bg: 'primary.200',
        color: 'primary.900',
      },
      closeButton: {
        color: 'primary.600',
        _hover: {
          color: 'primary.900',
        },
      },
    },
    brandSecondary: {
      container: {
        bg: 'secondary.200',
        color: 'secondary.900',
      },
      closeButton: {
        color: 'secondary.600',
        _hover: {
          color: 'primary.900',
        },
      },
    },
    brandTertiary: {
      container: {
        bg: 'tertiary.200',
        color: 'tertiary.900',
      },
      closeButton: {
        color: 'tertiary.600',
        _hover: {
          color: 'tertiary.900',
        },
      },
    },
    brandQuaternary: {
      container: {
        bg: 'quaternary.200',
        color: 'quaternary.900',
      },
      closeButton: {
        color: 'quaternary.600',
        _hover: {
          color: 'quaternary.900',
        },
      },
    },
    denotivePositive: {
      container: {
        bg: 'positive.50',
        color: 'positive.800',
      },
      closeButton: {
        color: 'positive.600',
        _hover: {
          color: 'positive.800',
        },
      },
    },
    denotiveWarning: {
      container: {
        bg: 'warning.50',
        color: 'warning.800',
      },
      closeButton: {
        color: 'warning.600',
        _hover: {
          color: 'warning.800',
        },
      },
    },
    denotiveDanger: {
      container: {
        bg: 'danger.50',
        color: 'danger.800',
      },
      closeButton: {
        color: 'danger.600',
        _hover: {
          color: 'danger.800',
        },
      },
    },
  },
  defaultProps: {
    variant: 'neutral',
    size: 'md',
  },
};

export type TagProps = {
  variant?:
    | 'neutral'
    | 'brand'
    | 'brandSecondary'
    | 'brandTertiary'
    | 'brandQuaternary'
    | 'denotivePositive'
    | 'denotiveWarning'
    | 'denotiveDanger';
  leftIcon?: IconDefinition;
  size?: 'sm' | 'md';
  children?: ReactNode;
  onClose?: () => void;
} & SelectedStyledSystemProps;

export const QuotientTag = forwardRef<TagProps, 'span'>(
  ({ variant, size, onClose, children, leftIcon, ...props }, ref) => {
    return (
      <Tag ref={ref} size={size} variant={variant} {...props}>
        {leftIcon && <QuotientIcon icon={leftIcon} />}
        <TagLabel>{children}</TagLabel>
        {onClose && <TagCloseButton onClick={onClose} />}
      </Tag>
    );
  },
);
