import numbro from 'numbro';

import { NumberFormat } from '../types/grid-types';
import { NUMBER_TYPES, CURRENCIES } from '../utils/constants';

export const NumberFormatter = {
  /** Returns the string value of the number after applying the associated formatting.
   * Used for displaying on the grid. The method returns both the number value itself and the value
   * with the prefix/suffix after applying the formatting. */
  formatForDisplay(
    datum: string,
    format?: NumberFormat,
  ): { formattedValueWithPrefixSuffix: string; formattedValue: number | undefined } {
    const PERCENTAGE_SUFFIX = '%';

    // This is useful for adding a prefix like '$' or '%'
    let prefix = '';
    let suffix = '';
    let effectiveDatum = datum;

    const options: numbro.Format = {
      thousandSeparated: !!format?.comma,
    };

    // Handle Percentages
    if (effectiveDatum && format?.type === NUMBER_TYPES.PERCENTAGE) {
      suffix = PERCENTAGE_SUFFIX;
      effectiveDatum = (Number(effectiveDatum) * 100).toString();
    } else {
      effectiveDatum = this.formatNumbro(effectiveDatum, options) || effectiveDatum;

      // Handle Currencies
      if (effectiveDatum && format?.currency_code && format?.type === NUMBER_TYPES.CURRENCY) {
        const currConfig = CURRENCIES.find((curr) => curr.code === format?.currency_code);
        if (currConfig?.suffix) {
          suffix = currConfig?.symbol ? ` ${currConfig?.symbol}` : '';
        } else {
          prefix = currConfig?.symbol ? `${currConfig?.symbol} ` : '';
        }
      }
    }

    // Handle decimal places
    if (effectiveDatum && format?.places) {
      effectiveDatum =
        this.formatNumbro(effectiveDatum, { ...options, mantissa: Number(format.places) }) || effectiveDatum;
    }

    return {
      formattedValue: effectiveDatum ? numbro.unformat(effectiveDatum) : undefined,
      formattedValueWithPrefixSuffix: `${prefix}${effectiveDatum}${suffix}`,
    };
  },

  /** Returns the raw value based on the number format */
  getValue(displayValue: string, format?: NumberFormat): string {
    const rawValue = numbro.unformat(displayValue as string);

    // For percentages, the actual value is 1/100 of what's displayed
    if (!!rawValue && format?.type === NUMBER_TYPES.PERCENTAGE) {
      return (rawValue * 0.01).toString();
    }
    return rawValue?.toString();
  },

  formatNumbro(value: string | number, options: numbro.Format) {
    let result;
    try {
      result = numbro(value).format(options);
    } catch (e) {
      return null;
    }
    if (value == null || typeof result !== 'string') {
      return null;
    }
    return result;
  },
};
