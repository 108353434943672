import { IconDefinition, faMerge, faDiamond } from '@fortawesome/pro-regular-svg-icons';
import { faTableList } from '@fortawesome/pro-regular-svg-icons/faTableList';

import { Box, Flex, Icon, Text } from 'quotient';

import { RenderTreeItemProps } from './types';

type Props = {
  itemContext: RenderTreeItemProps<any>;
  onSelectRow: (item: any) => void;
  activeNodes: string;
  activeNodeColor: string;
};

const worksheetIconTypes: Record<string, IconDefinition> = {
  component_instance: faDiamond,
  derived: faMerge,
};

export const TreeItemRow = ({ itemContext, onSelectRow, activeNodeColor, activeNodes }: Props) => {
  const { title, item, children } = itemContext;

  const iconKey = item.data.component_instance_id ? 'component_instance' : item.data.type;
  const worksheetIcon = worksheetIconTypes[iconKey] || faTableList;

  if (item.data.is_hidden) {
    return null;
  }
  const isActiveNode = activeNodes === item.data.id;

  return (
    <Flex
      alignItems="center"
      backgroundColor={isActiveNode ? activeNodeColor : 'transparent'}
      borderRadius="4px"
      cursor="pointer"
      height="36px"
    >
      <Flex
        alignItems="center"
        direction="row"
        gap={1}
        paddingLeft={3}
        paddingRight={2}
        onClick={() => onSelectRow(item)}
      >
        <Box backgroundColor={item.data.componentGroupColor} borderRadius="2px" height={3} width={1} />
        <Box backgroundColor="none" height={2} width={2} />
        <Icon icon={worksheetIcon} size="sm" />
        <Text color="primaryNeutral.900" data-testid={`test-${title}`} textStyle="smallRegular">
          {title}
        </Text>
      </Flex>
      <Box>{children}</Box>
    </Flex>
  );
};
