import {
  GridSelection,
  Item,
  EditableGridCell,
  CellClickedEventArgs,
  NumberCell,
  GridCell,
  GridCellKind,
} from '@glideapps/glide-data-grid';
import { IBounds } from 'react-laag';

import { DropdownCell } from './components/DropdownCell';
import { NumberFormatter } from './formatters/NumberFormatter';
import { DataGridColumn, DataGridDropdownConfig } from './types/grid-types';

export const onPasteWithSingleRowTiling: (
  target: Item,
  values: readonly (readonly string[])[],
  selection: GridSelection,
  getCellContent: Function,
  onCellsEdited: Function,
  onCellsDataChanged?: (cellUpdates: { cell: Item; newCellValue: EditableGridCell }[]) => void,
  dropdownOptionsMapper?: (
    rowData: Record<string, string | number | boolean>,
    columnId: string,
  ) => { label: string; value: string }[],
  dropdownConfig?: DataGridDropdownConfig,
) => boolean = (
  target,
  values,
  selection,
  getCellContent,
  onCellsEdited,
  onCellsDataChanged,
  dropdownOptionsMapper,
  dropdownConfig,
) => {
  const [col, row] = target;
  const { current } = selection;
  if (current) {
    const { range } = current;
    if (values.length === 1 && range.height > 1) {
      // hacky, only override if SINGLE row selection in the future we want full excel capability
      // that extends copy and paste buffer to the entire selection repeating tile
      // otherwise, do the default paste behavior
      const rowData = values[0];
      const newValues = [];
      for (let i = 0; i < rowData.length; i += 1) {
        for (let j = 0; j < range.height; j += 1) {
          const cellCopy = getCellContent([col + i, row + j], dropdownOptionsMapper, dropdownConfig) as any;
          if (cellCopy.data?.kind === 'dropdown-cell') {
            newValues.push({
              location: [col + i, row + j],
              value: {
                ...cellCopy,
                data: {
                  ...cellCopy.data,
                  label: rowData[i],
                  value: rowData[i],
                },
              },
            });
          } else {
            newValues.push({
              location: [col + i, row + j],
              value: {
                ...cellCopy,
                data: rowData[i],
                displayData: rowData[i],
              },
            });
          }
        }
      }

      onCellsEdited(newValues as any, onCellsDataChanged);
      return false;
    }
    return true;
  }
  return true;
};

export const onCellClickHandler: (
  cell: Item,
  event: CellClickedEventArgs,
  setCellPopoverContext: Function,
  trackCellClickPositions?: boolean,
  onCellClicked?: (cell: Item, bounds: IBounds) => void,
) => void = (cell, event, setCellPopoverContext, trackCellClickPositions, onCellClicked) => {
  const cellClickContext = {
    cell,
    bounds: {
      // translate to react-laag types
      left: event.bounds.x,
      top: event.bounds.y,
      width: event.bounds.width,
      height: event.bounds.height,
      right: event.bounds.x + event.bounds.width,
      bottom: event.bounds.y + event.bounds.height,
    },
  };

  if (trackCellClickPositions) {
    setCellPopoverContext(cellClickContext);
  }

  if (onCellClicked) {
    onCellClicked(cellClickContext.cell, cellClickContext.bounds);
  }
};

export const getCellValue = (newCellValue: EditableGridCell, columnConfig: DataGridColumn) => {
  // Numbers
  if (columnConfig.type === 'number') {
    return NumberFormatter.getValue(newCellValue.data as string, columnConfig.format);
  }

  // Default for string columns
  return newCellValue.data;
};

/**
 * Used to coerce the paste value before applying it to the target cell. This is essentially a 'pre' hook that
 * allows us to modify the paste value before applying it.
 * @param val The value to paste
 * @param cell The target cell
 * @returns GridCell or undefined
 */
export const coercePasteValue = (val: string, cell: GridCell) => {
  // For number cells we handle pasting of formatted values. We essentially extract the raw value
  // from the formatted one and apply that instead.
  if (cell.kind === GridCellKind.Number) {
    const rawValue = NumberFormatter.getValue(val);
    const cellCopy: NumberCell = {
      ...cell,
      data: Number(rawValue),
      displayData: rawValue,
    };
    return cellCopy;
  }
  return undefined;
};

export const isDropdownCell = (cell: EditableGridCell): cell is DropdownCell => {
  return cell.kind === 'custom' && (cell as DropdownCell).data.kind === 'dropdown-cell';
};
