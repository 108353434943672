import { I18nextProvider } from 'react-i18next';

import { i18n as prodI18N } from '../i18n';
import mockI18n from '../test/mocki18n';

export const I18nProvider = ({ children }: { children: React.ReactNode }) => {
  const isTest = process.env.NODE_ENV === 'test';
  return (
    <I18nextProvider defaultNS="translation" i18n={isTest ? mockI18n : prodI18N}>
      {children}
    </I18nextProvider>
  );
};
