import { useContext, createContext, useState, SetStateAction } from 'react';

import { I18nProvider } from './I18nProvider';

import { CompactSelection, DataGridColumn, GridSelection } from '../types/grid-types';

export type GlideContextType = {
  gridColumns: DataGridColumn[];
  selection: GridSelection;
  frozenColumn: number;
  setGridColumns: (gridColumns: DataGridColumn[] | SetStateAction<DataGridColumn[]>) => void;
  setSelection: (selection: GridSelection | SetStateAction<GridSelection>) => void;
  setFrozenColumn: (columnIndex: number | SetStateAction<number>) => void;
};

const GlideContext = createContext<GlideContextType>({} as GlideContextType);
export const useGlideContext = () => useContext(GlideContext);

export const GlideContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [gridColumns, setGridColumns] = useState<DataGridColumn[]>([]);
  const [selection, setSelection] = useState<GridSelection>({
    columns: CompactSelection.empty(),
    rows: CompactSelection.empty(),
  });
  const [frozenColumn, setFrozenColumn] = useState<number>(0);

  return (
    <I18nProvider>
      <GlideContext.Provider
        value={{
          gridColumns,
          selection,
          frozenColumn,
          setGridColumns,
          setSelection,
          setFrozenColumn,
        }}
      >
        {children}
      </GlideContext.Provider>
    </I18nProvider>
  );
};
