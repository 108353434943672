// Fork of https://github.com/glideapps/glide-data-grid/blob/main/packages/cells/src/cells/dropdown-cell.tsx

import {
  CustomCell,
  ProvideEditorCallback,
  CustomRenderer,
  getMiddleCenterBias,
  GridCellKind,
  TextCellEntry,
} from '@glideapps/glide-data-grid';
import * as React from 'react';
// eslint-disable-next-line no-restricted-imports
import Select, { MenuProps, components } from 'react-select';
// eslint-disable-next-line no-restricted-imports
import AsyncSelect from 'react-select/async';

import { Box } from 'quotient';
import { colors } from 'quotient/theme/foundations/colors/colors';

import { DataGridColumn, RowData } from '../types/grid-types';

interface CustomMenuProps extends MenuProps<any, any> {}
const CustomMenu: React.FC<CustomMenuProps> = ({ children, ...rest }) => {
  const { Menu } = components;
  return <Menu {...rest}>{children as any}</Menu>;
};

type DropdownOption = { value: string; label: string };

interface DropdownCellProps {
  readonly kind: 'dropdown-cell';
  readonly value: string | undefined | null;
  readonly label: string | undefined | null;
  readonly allowedValues: readonly DropdownOption[];
  readonly columnDefinition: DataGridColumn;
  readonly rowData: RowData;
  readonly asyncOptions?: {
    optionsResolver: (
      rowData: RowData,
      columnDefinition: DataGridColumn,
      filterValue: string,
    ) => Promise<DropdownOption[]>;
    defaultOptions?: { value: string; label: string }[];
  };
}

export type DropdownCell = CustomCell<DropdownCellProps>;

const Editor: ReturnType<ProvideEditorCallback<DropdownCell>> = ({ value: cell, onFinishedEditing, initialValue }) => {
  const { allowedValues, value: valueIn, label, rowData, columnDefinition, asyncOptions } = cell.data;

  const [value, setValue] = React.useState(valueIn);
  const [asyncOption, setAsyncOption] = React.useState({ label, value: valueIn });
  const [inputValue, setInputValue] = React.useState(initialValue ?? '');

  if (cell.readonly) {
    return (
      <Box>
        <TextCellEntry autoFocus={false} disabled highlight value={value ?? ''} />
      </Box>
    );
  }

  const asyncOptionsResolver = async (filterValue: string) => {
    const dropdownOptions = await asyncOptions?.optionsResolver(rowData, columnDefinition, filterValue);
    return dropdownOptions as DropdownOption[];
  };

  return (
    <Box>
      {asyncOptions && (
        <AsyncSelect
          autoFocus
          cacheOptions
          className="glide-select"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Menu: (props) => (
              <Box>
                <CustomMenu className="click-outside-ignore" {...props} />
              </Box>
            ),
          }}
          defaultOptions={asyncOptions.defaultOptions}
          inputValue={inputValue}
          loadOptions={asyncOptionsResolver}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          openMenuOnFocus
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: 'none',
            }),
            option: (base, { isFocused }) => ({
              ...base,
              cursor: isFocused ? 'pointer' : undefined,
              ':empty::after': {
                content: '"&nbsp;"',
                visibility: 'hidden',
              },
            }),
          }}
          theme={(t) => {
            return {
              ...t,
              colors: {
                ...t.colors,
                primary: colors.primary[400],
                primary25: colors.secondaryNeutral[200],
              },
            };
          }}
          value={asyncOption}
          onChange={async (option) => {
            if (option === null) return;
            setAsyncOption(option);
            await new Promise((r) => window.requestAnimationFrame(r));
            onFinishedEditing({
              ...cell,
              copyData: option.label as string,
              data: {
                ...cell.data,
                value: option.value,
                label: option.label,
              },
            });
          }}
          onInputChange={setInputValue}
        />
      )}

      {!asyncOptions && (
        <Select
          autoFocus
          className="glide-select"
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Menu: (props) => (
              <Box>
                <CustomMenu className="click-outside-ignore" {...props} />
              </Box>
            ),
          }}
          inputValue={inputValue}
          menuPlacement="auto"
          menuPortalTarget={document.body}
          openMenuOnFocus
          options={allowedValues}
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: 'none',
            }),
            option: (base, { isFocused }) => ({
              ...base,
              cursor: isFocused ? 'pointer' : undefined,
              ':empty::after': {
                content: '"&nbsp;"',
                visibility: 'hidden',
              },
            }),
          }}
          theme={(t) => {
            return {
              ...t,
              colors: {
                ...t.colors,
                primary: colors.primary[400],
                primary25: colors.secondaryNeutral[200],
              },
            };
          }}
          value={allowedValues.find((x) => x.value === value)}
          onChange={async (option) => {
            if (option === null) return;
            setValue(option.value);
            await new Promise((r) => window.requestAnimationFrame(r));
            onFinishedEditing({
              ...cell,
              copyData: option.label,
              data: {
                ...cell.data,
                value: option.value,
              },
            });
          }}
          onInputChange={setInputValue}
        />
      )}
    </Box>
  );
};

const renderer: CustomRenderer<DropdownCell> = {
  kind: GridCellKind.Custom,
  isMatch: (cell): cell is DropdownCell => (cell.data as any).kind === 'dropdown-cell',
  draw: (args, cell) => {
    const { ctx, theme, rect } = args;
    const displayText = cell.data?.label ?? '';
    if (displayText) {
      ctx.fillStyle = theme.textDark;
      ctx.fillText(
        displayText,
        rect.x + theme.cellHorizontalPadding,
        rect.y + rect.height / 2 + getMiddleCenterBias(ctx, theme),
      );
    }
    return true;
  },
  measure: (ctx, cell, theme) => {
    const { value } = cell.data;
    return (value ? ctx.measureText(value).width : 0) + theme.cellHorizontalPadding * 2;
  },
  provideEditor: () => ({
    editor: Editor,
    disablePadding: true,
    deletedValue: (v) => ({
      ...v,
      copyData: '',
      data: {
        ...v.data,
        value: '',
      },
    }),
  }),
  onPaste: (newOption, cellData) => {
    let pastedOption: DropdownOption | undefined;
    if (cellData.asyncOptions) {
      pastedOption = { label: newOption, value: newOption };
    } else {
      pastedOption = cellData.allowedValues.find((option) => {
        return option.label === newOption;
      });
    }
    return {
      ...cellData,
      // If the pasted option isn't part of the allowedValues options list, then retain
      // the current value
      value: pastedOption ? pastedOption.value : cellData.value,
    };
  },
};

export default renderer;
