import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import React, { MouseEventHandler } from 'react';

import { Button, Flex } from 'quotient';

export type Props = {
  filterCount?: number;
  callback: MouseEventHandler<Element>;
  buttonLabel?: string;
};

export const FilterIcon: React.VFC<Props> = ({ callback, filterCount, buttonLabel = 'Filters' }: Props) => {
  const filterCountDisplay = filterCount !== undefined && filterCount > 0 ? `(${filterCount})` : '';
  return (
    <Flex flexDirection="row">
      <Button data-testid="filter-icon-button" leftIcon={faSliders} unmask variant="ghost" onClick={callback}>
        {buttonLabel} {filterCountDisplay}
      </Button>
    </Flex>
  );
};
