import React, { useState, Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { PermissionsProvider } from 'contexts/Permissions/Permissions';
import { Center, ChakraWrapper, Spinner } from 'quotient';
import { Events } from 'utils/events';

import { queryClientConfig } from './configs/react-query';
import i18n from './i18n';
import { TQRouter } from './TQRouter';

type Props = {};

const App: React.VFC<Props> = () => {
  i18n.changeLanguage('en-US'); // for now until we use user locale
  const [queryClient] = useState(() => new QueryClient(queryClientConfig));

  useEffect(() => {
    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

    Events.track(Events.EVENT.tq.landing_page.displayed, {
      viewport_height: viewportHeight,
      viewport_width: viewportWidth,
    });
  }, []);
  return (
    <I18nextProvider defaultNS="translation" i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <PermissionsProvider>
          <ChakraWrapper customStyle={{ display: 'flex', flexDirection: 'column' }} fullHeight isEnabled>
            <HelmetProvider>
              <ReactQueryDevtools position="bottom-left" />
              <Suspense
                fallback={
                  <Center h="100vh">
                    <Spinner />
                  </Center>
                }
              >
                <TQRouter />
              </Suspense>
            </HelmetProvider>
          </ChakraWrapper>
        </PermissionsProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
};
export default App;
